<template>
	<div style="width: ; :100vw ">
		<a-row :gutter="24" type="flex" align="stretch">
			<a-col :span="24" class="mb-24">
				<a-row :gutter="24" type="flex" align="stretch" style="overflow: hidden">
					<a-card :bordered="false" class="header-solid h-full" style="width: 100vw" :bodyStyle="{ padding: '24px' }">
						<div class="content">
							<div v-for="(item, index) in integration" :key="index">
								<div class="integration-item">
									<div class="r-b-left">
										<img :src="item.img" width="40" height="40" alt="" />
									</div>
									<div class="r-b-right">
										<div class="r-title">{{ item.text }}</div>
										<div class="r-desc">{{ item.content }}</div>
										<div class="r-set-container">
											<div v-for="(i, j) in item.tag" :key="j">
												<div class="tag flex" @click="changeIcon(i, item)">
													<a-icon :type="i.icon" />
													<span>{{ i.name }} </span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</a-card>
				</a-row>
				<!-- 福利传递活动规则 -->
				<div class="welfare" v-show="welfare">
					<a-card class="welfare_card">
						<div class="flex">
							<h6>福利传递活动规则</h6>
							<a-icon class="icon" @click="close('welfare')" type="close-circle" />
						</div>
						<div>
							好友首次注册网红秀后，使用您的卡密进行兑换。好友将获得88Ai积分奖励，而您也将获得相同积分的奖励！
							分享方式多样，可以通过福利传递底部的其他方式进行邀请，如微信好友、朋友圈分享链接等具体操作步骤：
						</div>
						<br />
						<p>1.登录账号，进入福利传递分享页面；</p>
						<p>2.复制你的专属卡密并分享给好友；</p>
						<p>3.好友在权益兑换页面输入你的卡密；</p>
						<p>4.好友成功兑换后，你和好友均可获得88Ai积分的奖励。</p>
						<p>注意事项：</p>
						<p>1.卡密只能在单个账户上兑换一次，不得重复使用；</p>
						<p>2.可以分享卡密给任意好友，获得相应的奖励；</p>
						<p>3.输入卡密时，请确保准确无误，不可遗漏或错误输入任何部分；</p>
						<p>4.如有任何问题或疑问，请联系客服。本规则说明的解释权归本公司所有。</p>
					</a-card>
				</div>

				<!-- 夸一夸 -->
				<div class="praise" v-show="praise">
					<a-card class="praise_card">
						<div class="title">
							<p>夸一夸网红秀Ai积分送给你</p>
							<span>100%得Ai积分</span>
							<a-icon class="icon" @click="close('praise')" type="close-circle" />
						</div>
						<span>在社交媒体发布产品相关测评和好评推文，可获取 200 Ai积分</span>

						<p class="bloder">1.各平台社媒分享发布网红秀内容~</p>
						<p>不限制平台发布哦，可任务选择任何社媒平台，以下是举例~</p>
						<div class="imgList">
							<div v-for="(item, index) in platformImg" :key="index">
								<img :src="item" width="40" height="40" alt="" />
							</div>
						</div>
						<p class="bloder">2.联系客服</p>
						<p>上传截图1张作品,扫码联系客服，过审后即可获得积分</p>
						<div class="img">
							<img src="../assets/imgs/kefu.jpg" width="100" height="150" alt="" />
						</div>
					</a-card>
				</div>
				<!-- 群聊 -->
				<div class="crowd" v-show="crowd">
					<a-card class="crowd_card">
						<a-icon style="color: #fff" class="icon" @click="close('crowd')" type="close-circle" />
						<img :src="wxImg" width="150" height="150" alt="" />
					</a-card>
				</div>
				<!-- 签到 -->
				<!-- <div >
					<a-card class="crowd_card">
						<a-icon class="icon"  type="close-circle" />
						<img src="../assets/imgs/qiandaoback.png" width="150" height="150" alt="" />
					</a-card>
				</div> -->

				<!-- 好评  review-->
				<div class="platform" v-show="good">
					<a-card class="crowd_card">
						<a-icon class="icon" @click="close('good')" type="close-circle" />
						<img src="../assets/imgs/kefu.jpg" width="150" height="150" alt="" />
					</a-card>
				</div>

				<!-- 分享海报 -->
				<div class="chat_share_poster" v-show="show">
					<div class="ChatSharePoster" @click="$emit('close')">
						<div @click="downloadPage" class="poster_box">
							<div class="poster" id="pagePoster" ref="pagePoster" :style="{ background: posterBgColor }">
								<img width="280" height="320" :src="posterBgImage" crossorigin="undefined" alt="" />
								<div class="text_box">
									<span class="title">{{ form.title ? form.title : "秀秀" }}</span>
									<span class="content">{{ form.welcome ? form.welcome : "我是网红秀" }}</span>
								</div>
								<div class="user_box">
									<div class="infos">
										<div class="avatar">
											<img
												ref="mapCanvas"
												id="mapCanvas"
												class="img"
												:src="form.image ? form.image : logo"
												crossorigin="undefined" />
										</div>
										<div class="nickname">
											<div class="text1">{{ form.title ? form.title : "秀秀" }}</div>
											<div class="invite">邀您与我语音聊天</div>
										</div>
									</div>
									<div class="qrcode">
										<div class="img">
											<div ref="qrCodeUrl" id="qrCodeUrl"></div>
										</div>
									</div>
								</div>
							</div>
							<div class="poster_img">
								<image class="img" :src="posterImg" mode="aspectFit" show-menu-by-longpress></image>
							</div>
						</div>
						<div class="tips">点击图片保存到相册</div>
					</div>
				</div>
			</a-col>
		</a-row>
	</div>
</template>

<script>
	import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
	import html2canvas from "html2canvas";
	import QRCode from "qrcodejs2"; //
	export default {
		components: {},
		data() {
			return {
				integration: [
					{
						img: require("@assets/imgs/wangye.png"),
						number: 0,
						text: "网页",
						content: "用户在此链接可以直接和您的机器人聊天",
						tag: [
							{
								name: "复制链接",
								icon: "copy",
							},
							{
								name: "预览体验",
								icon: "eye",
							},
							// {
							// 	name: "域名部署",
							// 	icon: "cloud-upload",
							// },
						],
					},
					{
						img: require("@assets/imgs/pengyou.png"),
						number: 0,
						text: "朋友圈",
						content: "用户扫码后，可直接和您的机器人聊天",
						tag: [
							{
								name: "生成海报",
								icon: "eye",
							},
						],
					},
					{
						img: require("@assets/imgs/JS.png"),
						number: 0,
						text: "JS嵌入（完善中）",
						content: "可添加到网站的任何位置，将此 iframe 添加到 html 代码中",
						tag: [
							// {
							// 	name: "创建站点",
							// 	icon: "clock-circle",
							// },
							{
								name: "查看代码",
								icon: "eye",
							},
						],
					},
					{
						img: require("@assets/imgs/API.png"),
						number: 0,
						text: "API调用（完善中）",
						content: "通过API，可直接进行调用或发出请求",
						tag: [
							{
								name: "接口密钥",
								icon: "eye",
							},
							{
								name: "接口文档",
								icon: "file-text",
							},
						],
					},
					{
						img: require("@assets/imgs/chat.png"),
						number: 0,
						text: "微信聊天（完善中）",
						content: "使用企微账号自动回复微信用户单/群聊信息",
						tag: [
							{
								name: "创建账号",
								icon: "setting",
							},
							{
								name: "查看账号",
								icon: "eye",
							},
							{
								name: "创建聊天",
								icon: "message",
							},
							{
								name: "查看聊天",
								icon: "eye",
							},
						],
					},
					{
						img: require("@assets/imgs/account.png"),
						number: 0,
						text: "微信公众号（完善中）",
						content: "可在微信公众号后台配置，提供机器人服务",
						tag: [
							{
								name: "配置公众号",
								icon: "setting",
							},
						],
					},
					{
						img: require("@assets/imgs/fei.png"),
						number: 0,
						text: "飞书（完善中）",
						content: "在飞书群/单聊中提供机器人服务，仅限内部员工使用",
						tag: [
							{
								name: "配置飞书",
								icon: "setting",
							},
						],
					},
					{
						img: require("@assets/imgs/service.png"),
						number: 0,
						text: "微信客服（完善中）",
						content: "使用企微回复用户在微信咨询的消息，扫码即可实现与机器人1V1聊天",
						tag: [
							{
								name: "配置微信客服",
								icon: "setting",
							},
							{
								name: "配置文档",
								icon: "file-text",
							},
						],
					},
					{
						img: require("@assets/imgs/yin.png"),
						number: 0,
						text: "抖音（完善中）",
						content: "配置完成后，机器人可自动回复私信",
						tag: [
							{
								name: "扫码授权",
								icon: "scan",
							},
						],
					},
					{
						img: require("@assets/imgs/ding.png"),
						number: 0,
						text: "钉钉（完善中）",
						content: "在群/单聊中提供机器人服务，仅限内部员工使用",
						tag: [
							{
								name: "配置钉钉",
								icon: "setting",
							},
						],
					},
					{
						img: require("@assets/imgs/applet.png"),
						number: 0,
						text: "微信小程序（完善中）",
						content: "支持企业授权绑定小程序，提供机器人服务",
						tag: [
							{
								name: "扫码授权",
								icon: "scan",
							},
							{
								name: "查看小程序",
								icon: "eye",
							},
							{
								name: "配置文档",
								icon: "file-text",
							},
						],
					},
				],
				// 分享海报
				show: false,
				posterBgImage: this.$store.state.app.share?.image || "", // 海报背景图片
				posterBgColor: this.$store.state.app.share?.msg_title_bg_color || "", // 海报背景颜色
				UID: this.$store.state.user.userInfo.id || 0, // uid
				qrcodeValue: "", // 二维码参数
				textData: {}, // 绘制信息
				posterImg: "", // 海报图片
				display: "none",
				confirmLoading: false,
				qrcodeShare: "", //海报二维码
				ggg: "",
				form: {
					title: "",
					image: "",
				},

				//
				logo: require("@/assets/imgs/favicon.png"),
				platformImg: [
					require("@assets/imgs/pengyouquan.png"),
					require("@assets/imgs/kauishou.png"),
					require("@assets/imgs/bili.png"),
					require("@assets/imgs/zhihu.png"),
					require("@assets/imgs/xiaohongshu.png"),
					require("@assets/imgs/douyin.png"),
				],
				wxImg: "",
				visible: false,
				praise: false,
				crowd: false,
				welfare: false,
				introduce: false,
				good: false,
			};
		},
		watch: {
			config: {
				deep: true,
				handler: function (newValue, oldValue) {
					this.posterBgColor = newValue.share?.msg_title_bg_color || "";
					this.posterBgImage = newValue.share?.msg_title_bg || "";
				},
			},
			data: {
				deep: true,
				handler: function (newValue, oldValue) {
					if (!newValue?.id) {
						this.$emit("close");
						return;
					}
					if (newValue?.response != oldValue?.response) {
						this.textData = newValue;
						this.isCreate = true;
						this.posterImg = "";
					}
				},
			},
			$route: "getPath",
		},
		computed: {
			...mapGetters("user", ["token", "userInfo"]),
			...mapGetters("app", ["config"]),
		},
		created() {
			this.examine();
			this.$nextTick(() => {
				this.posterBgImage = this.posterBgImage + "?v=" + new Date().valueOf();
			});
		},
		mounted() {
			setTimeout(() => {
				let id = this.$store.state.user.userInfo.id;
				const spm = id + ".1.0.4.3";
				let sign = window.location.search.replace(/\?/g, "");
				this.qrcodeValue = this.$BASE_API + `?${sign}#/?share=${spm}`;
			}, 2000);
			this.creatQrCode();
		},
		methods: {
			...mapActions("user", ["getUserInfo"]),
			examine() {
				this.$http("drawing.examine").then((res) => {
					if (res.code == 1) {
						if (res.data.data.length != 0) {
							this.form = res.data.data[0];
						}
					}
				});
			},
			changeIcon(i, item) {
				switch (i.name) {
					case "复制链接":
						try {
							navigator.clipboard.writeText(this.qrcodeValue);
							this.$message.success("复制成功");
						} catch {
							this.$message.error("复制失败");
						}
						break;
					case "预览体验":
						this.$router.push("/role/chat?id=" + 196);
						break;
					case "生成海报":
						this.show = true;
						this.$nextTick(() => {
							this.createPosterImage();
						});
						break;
				}
			},

			/*********************分享海报*************************/
			// 获取生成的base64图片路径
			receiveRenderData(val) {
				const url = val.replace(/[\r\n]/g, ""); // 去除base64位中的空格
				this.posterImg = url;
			},
			// 生成二维码
			creatQrCode() {
				if (this.$refs.qrCodeUrl) this.$refs.qrCodeUrl.innerHTML = "";
				let id = this.$store.state.user.userInfo.id;
				const spm = id + ".1.0.2.2";
				let sign = window.location.search.replace(/\?/g, "");
				let url = "https://m.wanghongxiu.com";
				this.qrcodeShare = url + `?${sign}#/pages/role/init?scene=${spm}`;
				let data = new QRCode("qrCodeUrl", {
					text: this.qrcodeShare, // 需要转换为二维码的内容
					width: 60,
					height: 60,
				});
				console.log("生成二维码", data);
			},
			// 生成海报图片
			createPosterImage() {
				const time = setTimeout(() => {
					const dom = document.getElementById("pagePoster"); // 需要生成图片内容的 dom 节点
					html2canvas(dom, {
						width: dom.clientWidth, //dom 原始宽度
						height: dom.clientHeight,
						backgroundColor: "transparent",
						scrollY: 0, // html2canvas默认绘制视图内的页面，需要把scrollY，scrollX设置为0
						scrollX: 0,
						useCORS: true, //支持跨域
						scale: 1, // 设置生成图片的像素比例，默认是1，如果生成的图片模糊的话可以开启该配置项
					})
						.then((canvas) => {
							this.receiveRenderData(canvas.toDataURL("image/png"));
							this.ggg = canvas.toDataURL("image/png");
						})
						.catch((err) => {
							this.$message.error("【生成图片失败，请重试】!");
						});
					clearTimeout(time);
				}, 500);
			},
			// 下载海报
			downloadPage() {
				const file = document.createElement("a");
				file.href = this.ggg;
				file.download = this.form.title + "-网红秀";
				document.body.appendChild(file);
				file.click();
				document.body.removeChild(file);
				this.show = false;
			},

			// 签到状态 checkSign
			checkSign() {
				this.$http("user.checkSign").then((res) => {
					if (res.code === 1) {
						if (res.msg == "未签到") {
							this.equityExchange[2].button = "签到";
						} else {
							this.equityExchange[2].button = "已签到";
						}
					}
				});
			},
			// 签到 userSign
			userSign() {
				this.$http("user.userSign").then((res) => {
					if (res.code === 1) {
						this.equityExchange[2].button = "已签到";

						// 个人信息立即刷新
						this.getUserInfo();
						this.creationQuota[1].number = this.userInfo.usable || 0;
					}
				});
			},

			toLink() {
				let link = document.createElement("a");
				link.style.display = "none";
				link.href = "http://www.88jishou.com/links/9C0A2829A174FB84";
				link.target = "_blank";
				link.click();
			},
		},
	};
</script>

<style lang="scss" scoped>
	.content {
		overflow: hidden;
		.integration-item {
			width: calc((100% - 80px) / 2);
			height: 150px;
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			float: left;
			padding: 20px;
			margin-bottom: 20px;
			border-radius: 8px;
			border: 1px solid #e4e7ed;
			margin-left: 20px;
			.r-b-left {
				margin-right: 8px;
				font-size: 40px;
			}
			.r-b-right {
				margin-top: 15px;

				.r-title {
					font-size: 16px;
					font-weight: 500;
					color: #303133;
				}
				.r-desc {
					word-break: break-all;
					margin: 10px 0 24px;
					font-size: 12px;
					color: #9da3af;
					letter-spacing: 0.13px;
					line-height: 16px;
				}
				.r-set-container {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					margin-top: 24px;
					cursor: pointer;
					div {
						color: rgb(181, 190, 208);
					}
					.tag {
						font-size: 12px;
						margin-left: 0px;
						margin-right: 10px;
						span {
							display: inline-block;
							margin-left: 5px;
						}
						&:hover {
							color: #40a9ff;
						}
					}
				}
			}
		}
	}
	// 分享海报
	.ChatSharePoster {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 100;
		background: rgba(#000, 0.7);
		padding: 60px;
		display: flex;
		flex-direction: column;
		justify-content: center;

		.poster_box {
			height: 90%;
			overflow: hidden;
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;
			pointer-events: auto;
			.poster {
				width: 300px;
				height: 520px;
				position: relative;
				padding: 10px;
				background: #6a62d1;
				border-radius: 10px;
				position: fixed;
				top: 20%;
				left: 50%;
				.bgimg {
					width: 100%;
					height: auto;
				}

				.text_box {
					margin-top: 10px;
					background: #fff;
					text-indent: 10px;
					border-radius: 5px;
					padding: 7px;

					.title {
					}

					.content {
						margin-top: 5px;
						display: inline-block;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						overflow: hidden;
						text-overflow: ellipsis;
						-webkit-line-clamp: 2;
					}
				}

				.user_box {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-top: 20px;

					.infos {
						flex: 1;
						margin-right: 20px;
						display: flex;
						align-items: center;

						.avatar {
							width: 70px;
							height: 70px;
							border-radius: 50%;
							overflow: hidden;
							background: #fff;
							padding: 4px;

							.img {
								width: 100%;
								height: 100%;
								border-radius: 50%;
							}
						}

						.nickname {
							flex: 1;
							margin-left: 20px;
							color: #fff;
							.text1 {
								font-weight: 700;
								margin-bottom: 8px;
							}
						}
					}

					.nickname {
						.invite {
							font-size: 10px;
						}
					}

					.qrcode {
						width: 70px;
						height: 70px;
						background: #fff;
						padding: 5px;
						.img {
							width: 60px;
							height: 60px;
						}
					}
				}
			}

			.poster_img {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;

				display: flex;
				align-items: center;
				justify-content: center;
				.img {
					width: 100%;
					height: 100%;
				}
			}
		}

		.tips {
			position: fixed;
			bottom: 20%;
			left: 54%;
			color: #fff;
			font-size: 16px;
		}
	}
</style>
